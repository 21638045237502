<template>
  <div>
    <div class="card">
      <div class="bg-blue-light p-1">
        <TitleButton
            btnTitle="Register"
            :showBtn="false"
            :showAddNew="true"
            title="Contacts List"
            @onClickAddNewButton="onClickAddNewButton"
        />
      </div>

      <div class="my-2 px-2">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-5 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Roles</label>
            <v-select
                placeholder="Select Roles"
                v-model="role_id"
                :options="companyRules"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-2 d-flex align-items-end mt-2">
            <button
                style="min-width: 64px;"
                @click="getContactsList"
                class="btn btn-primary btn-block waves-effect waves-float waves-light"
            >Go
            </button>
          </div>
          <div class="col-2 col-12 col-sm-2 col-md-2 col-lg-2 mt-3">
            <button
                style="min-width: 64px;"
                @click="exportList"
                class="btn btn-primary btn-block waves-effect waves-float waves-light"
            >Export
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 px-2 my-2">
        <ListTable :contacts="contacts"/>
      </div>

      <div class="px-2 position-absolute bottom-0">
        <Pagination
            :offset="offset"
            @onPageChange="onPageChange"
            ref="pagination"
        />
      </div>
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import ListTable from '@/components/molecule/company/contact/ContactListTable.vue';
import {useRoute, useRouter} from "vue-router";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import handleRole from "@/services/modules/role";
import handleCompanyContact from "@/services/modules/companyContact";

const $route = useRoute();
const $router = useRouter();
const $store = useStore();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchCompanyContacts} = handleCompanyContact();
const {fetchCompanyDefaultRoles} = handleRole();
const offset = ref(10);
const loading = ref(false);
const contacts = ref([])
const companyRules = ref([])
const role_id = ref(null)
const searchTerm = ref('');
// computed
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const companyId = computed(() => $route.params.companyId);
const token = localStorage.getItem('token');

//methods
const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};
const resetPagination = () => {
  $store.commit('resetPagination')
};

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})
  await getContactsList()
}
const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const onClickAddNewButton = () => {
  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId,
  }
  $router.push({
    name: 'contact-register',
    params: params,
    query: $route.query
  })
}
const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  if (page) query += '&page=' + page.value
  if (role_id.value) query += '&role_id=' + role_id.value
  if (searchTerm) query += '&q=' + searchTerm.value
  return query
};

async function getContactsList() {
  try {
    const res = await fetchCompanyContacts(getQuery());
    if (!res.status) {
      contacts.value = [];
      return resetPagination()
    }
    contacts.value = res.data.data;
    await setPagination(res.data)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const exportList = () => {
  let objQuery = {
    company_id: $route.params.companyId,
    role_id: role_id.value ?? '',
    _token: token
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/contacts/?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted(() => {
  loading.value = true;
  const companyQuery = `?company_id=${companyId.value}`
  const rolesRes = fetchCompanyDefaultRoles(companyQuery)
  Promise.all([
    rolesRes.then(res => {
      if (res.data) companyRules.value = res.data
    })
  ]).then(() => {
    loading.value = false
  }).catch((err) => {
    loading.value = false
  })
  getContactsList()
})
</script>
